export interface Block {
  _key: string
  _type: string
  children: [
    {
      _key: string
      _type: string
      marks: []
      text: string
    },
  ]
  markDefs: []
  style: string
}

export function toPlainText(blocks: Array<Block> | string): string {
  if (typeof blocks === 'string') {
    return blocks
  } else {
    return (
      blocks
        // loop through each block
        .map((block) => {
          // if it's not a text block with children,
          // return nothing
          if (block._type !== 'block' || !block.children) {
            return ''
          }
          // loop through the children spans, and join the
          // text strings
          return block.children.map((child) => child.text).join('')
        })
        // join the paragraphs leaving split by two linebreaks
        .join('\n\n')
    )
  }
}
