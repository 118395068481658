import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { toPlainText, Block } from './toPlainText'

interface Props {
  title: string
  description?: Array<Block> | string
  location?: string
  image?: string
  article?: string
}

interface SocialMedia {
  user: string
  platform: string
}

export const SEO = ({ title, description, image, article, location }: Props): JSX.Element => {
  const { pathname } = useLocation()
  const data = useStaticQuery(query)

  const { contactInfo, siteSEO, logo } = data.sanitySiteSettings
  const { defaultTitle, defaultDescription, defaultLocation } = siteSEO
  const { socialMedia } = contactInfo
  const { defaultImage } = logo
  const { siteUrl } = data.site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    location: location || defaultLocation,
    description: (description && toPlainText(description)) || toPlainText(defaultDescription),
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  const twitterUsername = (socialMedia: Array<SocialMedia>): string => {
    let un = '@deadhead.design'

    socialMedia.map((item) => {
      if (item.platform === 'twitter') {
        un = item.user
      }
    })

    return un
  }

  return (
    <Helmet title={seo.title} titleTemplate={`${seo.title} | Queen City Flying Service, ${seo.location}`}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && <meta property="og:description" content={seo.description} />}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && <meta name="twitter:creator" content={twitterUsername(socialMedia)} />}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && <meta name="twitter:description" content={seo.description} />}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    sanitySiteSettings {
      contactInfo {
        socialMedia {
          platform
          user
        }
      }
      siteSEO {
        defaultTitle: title
        defaultDescription: _rawDescription
        defaultLocation: location
      }
      logo {
        asset {
          fixed {
            defaultImage: src
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
